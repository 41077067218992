import {
  WcFormControl,
  WcFormHelperText,
  WcStack,
  WcSnackBar,
  WcInputAdornment,
  WcIconButton,
  WcTextField,
} from "@bpipartners/webui";
import { useTranslations } from "next-intl";
import React from "react";
import { useTheme } from "@guberno/utils/theme";
import { isEmail } from "validator";
import { IoMdCloseCircleOutline } from "react-icons/io";

interface ForgotPasswordFormProps {
  onForgotPasswordDetails: (val: ForgotPasswordFormState) => void;
  data?: any;
}

export interface ForgotPasswordFormState {
  userName: string;
}

interface ForgotPasswordFormError {
  userName: string;
}

export const ForgotPasswordForm = React.forwardRef(
  (props: ForgotPasswordFormProps, ref) => {
    const t = useTranslations();
    const { onForgotPasswordDetails } = props;
    const theme = useTheme();
    const [values, setValues] = React.useState<ForgotPasswordFormState>({
      userName: "",
    });
    const [formIsSubmitted, setFormIsSubmitted] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);
    const [snackBarMessage, setSnackBarMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("");
    const [formErrors, setFormErrors] = React.useState<ForgotPasswordFormError>(
      {
        userName: t("feedback.missingValues"),
      }
    );

    const handleChange =
      (prop: keyof ForgotPasswordFormState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === "userName" || prop === "password") {
          if (prop === "userName" && event.target.value === "") {
            formErrors.userName = t("feedback.missingValues");
          } else {
            if (!isEmail(event.target.value)) {
              formErrors.userName = t("feedback.invalidEmail");
            } else {
              formErrors.userName = "";
            }
          }
        }
        setFormErrors({ ...formErrors });
        setValues({ ...values, [prop]: event.target.value.trim() });
      };

    React.useImperativeHandle(ref, () => ({
      onSubmit() {
        setFormIsSubmitted(true);
        if (Object.values(formErrors).some((item) => item.length > 0)) {
          setSnackBar(true);
          setSeverity("error");
          setSnackBarMessage(t("feedback.missingValues"));
        } else {
          setFormErrors({ userName: "" });
          onForgotPasswordDetails(values);
        }
      },
    }));

    const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }

      setSnackBar(false);
    };
    return (
      <WcStack spacing={2} padding={2} width={450} alignItems="center">
        <WcStack
          alignItems="center"
          direction={"column"}
          width={350}
          spacing={4}
        >
          <WcFormControl fullWidth variant="outlined">
            <WcTextField
              id="outlined-userName"
              label={t("login.emailAddress")}
              required
              value={values.userName}
              onChange={handleChange("userName")}
              customVariant="default"
              variant="outlined"
              size="medium"
              InputProps={{
                endAdornment: (
                  <WcInputAdornment position="end">
                    {values.userName.length > 0 && (
                      <WcIconButton
                        disableRipple
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setValues({
                            ...values,
                            userName: "",
                          })
                        }
                        color="primary"
                        edge="end"
                        size="large"
                      >
                        <IoMdCloseCircleOutline />
                      </WcIconButton>
                    )}
                  </WcInputAdornment>
                ),
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: theme.appColors.surfaceContainerLowest,
                },
              }}
            />
            {formIsSubmitted && (
              <WcFormHelperText
                sx={{
                  color: formErrors.userName ? theme.appColors.error : "",
                }}
                id="filled-userName-helper-text"
              >
                {formErrors.userName ? formErrors.userName : ""}
              </WcFormHelperText>
            )}
          </WcFormControl>
        </WcStack>
        <WcSnackBar
          openState={snackBar}
          //@ts-ignore
          severity={severity}
          message={snackBarMessage}
          //@ts-ignore
          onClose={handleClose}
        ></WcSnackBar>
      </WcStack>
    );
  }
);

ForgotPasswordForm.displayName = "ForgotPasswordForm";

export default ForgotPasswordForm;
